/* You can add global styles to this file, and also import other style files */
.layout-content {
    padding: 0px;
    flex: 1 1 auto;
}
.layout-content-wrapper {
    padding-top: 2rem;
    padding-left: 0px;
    padding-bottom: 0px;
    padding-right: 0px;
    justify-content: space-between;
    min-height: 100vh;
    transition: padding-left .2s;
}
.layout-sidebar {
    background: transparent;
}
.footer-bg {
    background-color: var(--topbar-bg-color);
}